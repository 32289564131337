<template>
  <div class="collapsible-text">
    <!-- Скрытый контейнер для получения HTML из слота -->
    <div style="display: none;">
      <div ref="content">
        <slot></slot>
      </div>
    </div>

    <!-- Отображаемый контент -->
    <div v-html="processedContent"></div>

    <!-- Кнопка переключения -->
    <button
      v-if="showToggle"
      @click="isExpanded = !isExpanded"
      class="collapsible-text__toggle"
      :aria-expanded="isExpanded"
      :aria-label="isExpanded ? 'Свернуть' : 'Читать дальше'"
    >
      {{ isExpanded ? collapseText : expandText }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'CollapsibleText',
  props: {
    maxLength: {
      type: Number,
      required: true
    },
    ellipsis: {
      type: String,
      default: '...'
    },
    expandText: {
      type: String,
      default: 'Читать дальше'
    },
    collapseText: {
      type: String,
      default: 'Свернуть'
    }
  },
  data() {
    return {
      isExpanded: false,
      fullContent: '',
      truncatedContent: ''
    };
  },
  computed: {
    processedContent() {
      return this.isExpanded ? this.fullContent : this.truncatedContent;
    },
    showToggle() {
      // Показываем кнопку только если контент был обрезан
      return this.fullContent !== this.truncatedContent;
    }
  },
  mounted() {
    this.processContent();
  },
  updated() {
    this.processContent();
  },
  methods: {
    processContent() {
      const contentElement = this.$refs.content;
      if (!contentElement) return;

      const originalHtml = contentElement.innerHTML;
      const plainText = contentElement.textContent;

      // Если текст короче максимальной длины - используем оригинальный контент
      if (plainText.length <= this.maxLength) {
        this.fullContent = originalHtml;
        this.truncatedContent = originalHtml;
        return;
      }

      // Если нужно обрезать
      this.fullContent = originalHtml;
      this.truncatedContent = this.truncateHtml(originalHtml, this.maxLength);
    },

    truncateHtml(html, maxLength) {
      let textLength = 0;
      let inTag = false;
      const tagStack = [];
      let result = '';
      let i = 0;

      while (textLength < maxLength && i < html.length) {
        const char = html[i];

        if (char === '<') {
          inTag = true;
          const tagEnd = html.indexOf('>', i);
          if (tagEnd === -1) break;

          const fullTag = html.slice(i, tagEnd + 1);

          // Обработка тегов
          if (fullTag.startsWith('</')) {
            tagStack.pop();
          } else if (!fullTag.endsWith('/>')) {
            const tagNameMatch = fullTag.match(/<\/?([a-zA-Z]+)/);
            if (tagNameMatch) {
              tagStack.push(tagNameMatch[1]);
            }
          }

          result += fullTag;
          i = tagEnd + 1;
          inTag = false;
          continue;
        }

        if (!inTag) {
          textLength++;
          if (textLength > maxLength) break;
        }

        result += char;
        i++;
      }

      // Добавляем многоточие и закрывающие теги
      result += this.ellipsis;
      while (tagStack.length > 0) {
        result += `</${tagStack.pop()}>`;
      }

      return result;
    }
  }
};
</script>

<style lang="scss" scoped>
@use '/frontend/scss/base/media.scss' as media;
@use '/frontend/scss/base/variables.scss' as var;

.collapsible-text {
  font-family: var.$CeraRoundPro;
  color: var.$colorEndeavour;

  @include media.fluid('font-size', 14px, 18px);
  @include media.fluid('line-height', 24px, 24px);

  &__toggle {
    font-family: var.$CeraRoundPro;
    color: var.$colorCyan;
    background-color: transparent;
    font-weight: 700;
    @include media.fluid('font-size', 14px, 16px);
    @include media.fluid('line-height', 24px, 20px);

    @include media.fluid('margin-top', 8px, 24px);

    cursor: pointer;
    border: none;
  }
}
</style>
